/**
 * @ComponentFor NewsPageViewModel
 */
import React from 'react';
import { EpiProperty, epiPropertyValue, Breakpoint } from '@avensia/scope';
import { styled } from '@glitz/react';
import { media } from '@glitz/core';
import NewsPageViewModelType from './NewsPageViewModel.type';
import { Main, Appearance, Heading } from 'Shared/PageLayout';
import connect from 'Shared/connect';
import * as style from 'Shared/Style';

type ConnectStateType = {
  currentBreakpoint: number;
};

type PropType = ConnectStateType & NewsPageViewModelType;

const CenterContent = styled.div({
  width: '100%',
  textAlign: 'left',
});

const MainWrapper = styled(Main, {
  width: '100%',
  margin: {
    top: '39px',
    left: 'auto',
    right: 'auto',
  },
  padding: {
    left: '28px',
    right: '28px',
  },
  ...media(style.mediaMinQueries[Breakpoint.Small], {
    margin: {
      top: '80px',
    },
    padding: {
      left: '4vw',
      right: '4vw',
    },
  }),
  ...media(style.mediaMinQueries[Breakpoint.Large], {
    padding: {
      left: '8vw',
      right: '8vw',
    },
  }),
});

const HeadingWrapper = styled.div({
  ...media(style.mediaMinQueries[Breakpoint.Small], {
    paddingTop: '40px',
    paddingRight: 0,
    paddingBottom: '40px',
    paddingLeft: 0,
  }),
});

const BkgPattern = styled.div({
  ...media(style.mediaMinQueries[Breakpoint.Small], {
    backgroundImage:
      'url(' +
      require('../../Shared/Image/PatternLeft.png') +
      '), url(' +
      require('../../Shared/Image/PatternRight.png') +
      ') ',
    backgroundPosition: 'top left, top right',
    backgroundRepeat: 'repeat-y',
    position: 'absolute',
    top: 0,
    bottom: 0,
    width: '100%',
    zIndex: -1,
  }),
});

const MenuBkg = styled.div({
  position: 'absolute',
  background: style.primaryDark,
  zIndex: -1,
  top: 0,
  width: '100%',
  height: '65px',
  ...media(style.mediaMinQueries[Breakpoint.Medium], {
    height: '80px',
  }),
});

const DateBox = styled.div({
  height: '44px',
  minWidth: '81px',
  width: '81px',
  backgroundColor: style.primaryDark,
  color: style.WHITE,
  font: {
    size: '16px',
    weight: 500,
  },
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  alignSelf: 'flex-start',
  margin: {
    bottom: '15px',
  },
  ...media(style.mediaMinQueries[Breakpoint.Small], {
    height: '60px',
    minWidth: '114px',
    width: '114px',
    font: {
      size: '24px',
    },
  }),
});

class NewsPage extends React.Component<PropType> {
  constructor(props: PropType) {
    super(props);
  }

  dateFormat = (date: string) => {
    const dateMonth = new Date(date);
    const locale = 'en-us';
    const month = dateMonth.toLocaleString(locale, {
      month: 'short',
    });
    const dateDay = new Date(date).getDate();
    const fdate = month + ' ' + dateDay;
    return fdate;
  };

  render() {
    return (
      <>
        <BkgPattern />
        <MenuBkg />
        <MainWrapper appearance={[Appearance.BackgroundImage, Appearance.Center]}>
          <HeadingWrapper>
            <Heading title={this.props.content.title} breadcrumbs={this.props.breadcrumbs} />
          </HeadingWrapper>
          <CenterContent id="mainContent">
            <DateBox>{this.dateFormat(epiPropertyValue(this.props.content.date))}</DateBox>
            <p>{epiPropertyValue(this.props.content.ingress)}</p>
            <EpiProperty for={this.props.content.mainContent} />
          </CenterContent>
        </MainWrapper>
      </>
    );
  }
}

export default connect<ConnectStateType>(state => ({
  currentBreakpoint: state.currentBreakpoint,
}))(NewsPage);
