/**
 * @ComponentFor EventListEditorialContentPageViewModel
 */
import React from 'react';
import { EpiProperty, epiPropertyValue, Breakpoint } from '@avensia/scope';
import { styled } from '@glitz/react';
import Viewport from 'Shared/Viewport';
import EventListEditorialContentPageViewModelType from './EventListEditorialContentPageViewModel.type';
import EventPageListItem from './EventPageListItem.type';
import * as style from 'Shared/Style';
import { media } from '@glitz/core';
import { Main, Appearance } from 'Shared/PageLayout';
import Link from 'Shared/Link';
import Image from 'Shared/Image';
import connect from 'Shared/connect';
import AltBreadcrumbs from 'Shared/Breadcrumbs/alt';
import FadeIntoView from 'Shared/Animations/FadeIntoView';

type ConnectStateType = {
  currentBreakpoint: number;
};

type PropType = ConnectStateType & EventListEditorialContentPageViewModelType;

const CenterContent = styled.div({
  width: '100%',
  ...media(style.mediaMinQueries[Breakpoint.Small], {
    textAlign: 'center',
  }),
});

const MainWrapper = styled(Main, {
  width: '100%',
  margin: {
    top: '29px',
    left: 'auto',
    right: 'auto',
  },
  padding: {
    left: '28px',
    right: '28px',
  },
  ...media(style.mediaMinQueries[Breakpoint.Medium], {
    margin: {
      top: '99px',
    },
    padding: {
      left: '4vw',
      right: '4vw',
    },
  }),
  ...media(style.mediaMinQueries[Breakpoint.Large], {
    padding: {
      left: '8vw',
      right: '8vw',
    },
  }),
});

const BkgPattern = styled.div({
  ...media(style.mediaMinQueries[Breakpoint.Small], {
    backgroundImage:
      'url(' +
      require('../../Shared/Image/PatternLeft.png') +
      '), url(' +
      require('../../Shared/Image/PatternRight.png') +
      ') ',
    backgroundPosition: 'top left, top right',
    backgroundRepeat: 'repeat-y',
    position: 'absolute',
    top: 0,
    bottom: 0,
    width: '100%',
    zIndex: -1,
  }),
});

const ImageContainer = styled.div({
  position: 'absolute',
  top: 0,
  width: '100%',
  overflow: 'hidden',
  zIndex: -1,
  height: 'calc(800px - 50vw)',
  ...media(style.mediaMinQueries[Breakpoint.Small], {
    height: '560px',
  }),
});

const BkgImage = styled(Image, {
  width: '100%',
  objectFit: 'cover',
  height: 'calc(800px - 50vw)',
  ...media(style.mediaMinQueries[Breakpoint.Small], {
    height: '560px',
  }),
});

const ImageHeaderContentWrapper = styled.div({
  color: style.WHITE,
  textShadow: '0 0 10px rgba(0,0,0,0.5)',
  margin: {
    bottom: '80px',
    x: 'auto',
  },
  maxWidth: style.maxContentWidth - 480,
  padding: {
    top: '18px',
    bottom: '0',
  },
  ...media(style.mediaMinQueries[Breakpoint.Small], {
    padding: {
      top: '80px',
    },
  }),
  height: 'calc(690px - 50vw)',
  ...media(style.mediaMinQueries[Breakpoint.Small], {
    height: '450px',
  }),
});

const ImageHeaderFontSize = styled.div({
  fontSize: '1em',
  ...media(style.mediaMinQueries[Breakpoint.Tiny], {
    fontSize: '1.2em',
  }),
});

const H1 = styled.h1({
  margin: {
    top: 0,
    bottom: '32px',
  },
  fontWeight: 500,
});

const Event = styled.div({
  ':hover': {
    textDecoration: 'none',
  },
  margin: {
    left: 'auto',
    right: 'auto',
    bottom: '10px',
  },
  ...media(style.mediaMinQueries[Breakpoint.Small], {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    padding: {
      bottom: '30px',
    },
    margin: {
      bottom: 0,
    },
  }),
});

const PastEvent = styled.div({
  ':hover': {
    textDecoration: 'none',
  },
  padding: {
    bottom: '25px',
  },
  ...media(style.mediaMinQueries[Breakpoint.Small], {
    display: 'flex',
    padding: {
      bottom: '8px',
    },
  }),
});

const EventImage = styled.img({
  height: '172px',
  width: '100%',
  objectFit: 'cover',
  objectPosition: 'center',
  margin: {
    bottom: '15px',
    left: 'auto',
    right: 'auto',
  },
  ...media(style.mediaMinQueries[Breakpoint.Small], {
    minWidth: '400px',
    margin: {
      bottom: 0,
      left: 0,
      right: '30px',
    },
    height: '235px',
  }),
});

const EventInfo = styled.div({
  width: '100%',
  ...media(style.mediaMaxQueries[Breakpoint.Small], {
    display: 'flex',
    margin: {
      bottom: '10px',
      left: 0,
    },
  }),
});

const PastEventInfo = styled.div({
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  margin: {
    bottom: '10px',
    left: 0,
  },
});

const DateBox = styled.div({
  height: '44px',
  minWidth: '81px',
  width: '81px',
  backgroundColor: style.primaryDark,
  color: style.WHITE,
  font: {
    size: '16px',
    weight: 500,
  },
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  alignSelf: 'flex-start',
  ...media(style.mediaMinQueries[Breakpoint.Small], {
    height: '60px',
    minWidth: '114px',
    width: '114px',
    font: {
      size: '24px',
    },
    margin: {
      right: '15px',
    },
  }),
});

const FutureEvents = styled.div({
  textAlign: 'left',
  width: '100%',
  height: 'auto',
  backgroundPosition: 'top left',
  backgroundRepeat: 'no-repeat',
  padding: {
    top: '16px',
    bottom: '14px',
  },
  ...media(style.mediaMinQueries[Breakpoint.Small], {
    flexDirection: 'column',
    padding: {
      left: 0,
      top: 30,
      bottom: 0,
    },
  }),
});

const EventTitle = styled.span({
  color: style.primary,
  font: {
    size: '16px',
    weight: 500,
  },
  ...media(style.mediaMinQueries[Breakpoint.Small], {
    margin: {
      left: 0,
    },
    font: {
      size: '24px',
    },
  }),
});

const EventPlace = styled.span({
  color: 'black',
  font: {
    size: '16px',
    weight: 300,
  },
  ...media(style.mediaMinQueries[Breakpoint.Small], {
    font: {
      size: '24px',
      weight: 300,
    },
    margin: {
      bottom: 0,
      left: '6px',
    },
  }),
});

const PastEvents = styled.div({
  textAlign: 'left',
  ...media(style.mediaMinQueries[Breakpoint.Small], {
    margin: {
      bottom: '66px',
    },
  }),
});

const TitlePastEvents = styled.h2({
  margin: {
    top: '25px',
    bottom: '20px',
  },
});

const EventLink = styled(Link, {
  ':hover': {
    textDecoration: 'none',
  },
});

const Info = styled.div({
  width: '100%',
  margin: {
    top: '15px',
  },
  ...media(style.mediaMaxQueries[Breakpoint.Small], {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    margin: {
      top: '0',
      left: '15px',
    },
  }),
});

const PastInfo = styled.div({
  width: '100%',
  margin: {
    top: '15px',
    bottom: '15px',
  },
  ...media(style.mediaMaxQueries[Breakpoint.Small], {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    margin: {
      top: '0',
      left: '15px',
    },
  }),
});

const Preamble = styled.p({
  color: style.BLACK,
  flexBasis: '100%',
  width: '100%',
  margin: {
    top: '15px',
    bottom: 0,
  },
  ...media(style.mediaMinQueries[Breakpoint.Small], {
    flexBasis: '100%',
  }),
});

class EventListEditorialContentPage extends React.Component<PropType> {
  dateFormat = (item: EventPageListItem) => {
    const dateMonth = new Date(item.date);
    const locale = 'en-us';
    const month = dateMonth.toLocaleString(locale, {
      month: 'short',
    });
    const dateDay = new Date(item.date).getDate();
    const date = month + ' ' + dateDay;
    return date;
  };

  render() {
    const FutureEventList = this.props.futureEvents;
    const PastEventList = this.props.pastEvents;
    const eventsExist = FutureEventList.length || PastEventList.length;

    return (
      <>
        <BkgPattern />
        <ImageContainer>
          <BkgImage src={epiPropertyValue(this.props.content.imageUrl)} />
        </ImageContainer>
        <MainWrapper appearance={[Appearance.BackgroundImage, Appearance.Center]}>
          {this.props.breadcrumbs && <AltBreadcrumbs breadcrumbs={this.props.breadcrumbs} />}
          <CenterContent id="mainContent">
            <ImageHeaderContentWrapper>
              <FadeIntoView>
                <ImageHeaderFontSize>
                  <EpiProperty component={H1} for={this.props.content.pageTitle} />
                  <EpiProperty for={this.props.content.headerContent} />
                </ImageHeaderFontSize>
              </FadeIntoView>
            </ImageHeaderContentWrapper>
            {eventsExist && (
              <>
                {FutureEventList.length > 0 ? (
                  <FutureEvents>
                    {FutureEventList.map((item, i) => (
                      <EventLink key={i} to={item.url}>
                        <Event>
                          {item.image ? (
                            <>
                              <EventImage src={item.image} />
                              <EventInfo>
                                <DateBox>
                                  {item.date && item.date !== '0001-01-01T00:00:00'
                                    ? this.dateFormat(item)
                                    : item.noDateYet}
                                </DateBox>
                                <Info>
                                  <EventTitle>{item.title}</EventTitle>
                                  {item.place && <EventPlace>{item.place}</EventPlace>}
                                </Info>
                                <Viewport>
                                  {(isCompact: boolean) => !isCompact && <Preamble>{item.ingress}</Preamble>}
                                </Viewport>
                              </EventInfo>
                            </>
                          ) : (
                            <>
                              <EventInfo style={{ marginTop: 20, marginBottom: 0 }}>
                                <DateBox>
                                  {item.date && item.date !== '0001-01-01T00:00:00'
                                    ? this.dateFormat(item)
                                    : item.noDateYet}
                                </DateBox>
                                <Info>
                                  <EventTitle>{item.title}</EventTitle>
                                  {item.place && <EventPlace>{item.place}</EventPlace>}
                                </Info>
                              </EventInfo>
                              <Preamble>{item.ingress}</Preamble>
                            </>
                          )}
                        </Event>
                      </EventLink>
                    ))}
                  </FutureEvents>
                ) : (
                  <p>{epiPropertyValue(this.props.content.noEvents)}</p>
                )}
                {PastEventList.length > 0 ? (
                  <PastEvents>
                    <TitlePastEvents>{epiPropertyValue(this.props.content.pastEventsTitle)}</TitlePastEvents>
                    {PastEventList.map((item, i) => (
                      <EventLink to={item.url} key={i}>
                        <PastEvent>
                          <PastEventInfo>
                            <DateBox>
                              {item.date && item.date !== '0001-01-01T00:00:00'
                                ? this.dateFormat(item)
                                : item.noDateYet}
                            </DateBox>

                            <PastInfo>
                              <EventTitle>{item.title}</EventTitle>
                              {item.place && <EventPlace>{item.place}</EventPlace>}
                            </PastInfo>
                          </PastEventInfo>
                        </PastEvent>
                      </EventLink>
                    ))}
                  </PastEvents>
                ) : null}
              </>
            )}
          </CenterContent>
        </MainWrapper>
      </>
    );
  }
}

export default connect<ConnectStateType>(state => ({
  currentBreakpoint: state.currentBreakpoint,
}))(EventListEditorialContentPage);
