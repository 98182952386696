/**
 * @ComponentFor NewsListEditorialContentPageViewModel
 */
import React from 'react';
import { EpiProperty, epiPropertyValue, Breakpoint } from '@avensia/scope';
import { styled } from '@glitz/react';
import NewsListEditorialContentPageViewModelType from './NewsListEditorialContentPageViewModel.type';
import NewsPageListItem from './NewsPageListItem.type';
import * as style from 'Shared/Style';
import { media } from '@glitz/core';
import { Main, Appearance } from 'Shared/PageLayout';
import Link from 'Shared/Link';
import Image from 'Shared/Image';
import connect from 'Shared/connect';
import AltBreadcrumbs from 'Shared/Breadcrumbs/alt';
import FadeIntoView from 'Shared/Animations/FadeIntoView';

type ConnectStateType = {
  currentBreakpoint: number;
};

type PropType = ConnectStateType & NewsListEditorialContentPageViewModelType;

const CenterContent = styled.div({
  width: '100%',
  ...media(style.mediaMinQueries[Breakpoint.Small], {
    textAlign: 'center',
  }),
});

const MainWrapper = styled(Main, {
  width: '100%',
  margin: {
    top: '29px',
    left: 'auto',
    right: 'auto',
  },
  padding: {
    left: '28px',
    right: '28px',
  },
  ...media(style.mediaMinQueries[Breakpoint.Medium], {
    margin: {
      top: '99px',
    },
    padding: {
      left: '4vw',
      right: '4vw',
    },
  }),
  ...media(style.mediaMinQueries[Breakpoint.Large], {
    padding: {
      left: '8vw',
      right: '8vw',
    },
  }),
});

const BkgPattern = styled.div({
  ...media(style.mediaMinQueries[Breakpoint.Small], {
    backgroundImage:
      'url(' +
      require('../../Shared/Image/PatternLeft.png') +
      '), url(' +
      require('../../Shared/Image/PatternRight.png') +
      ') ',
    backgroundPosition: 'top left, top right',
    backgroundRepeat: 'repeat-y',
    position: 'absolute',
    top: 0,
    bottom: 0,
    width: '100%',
    zIndex: -1,
  }),
});

const ImageContainer = styled.div({
  position: 'absolute',
  top: 0,
  width: '100%',
  overflow: 'hidden',
  zIndex: -1,
  height: 'calc(800px - 50vw)',
  ...media(style.mediaMinQueries[Breakpoint.Small], {
    height: '560px',
  }),
});

const BkgImage = styled(Image, {
  width: '100%',
  objectFit: 'cover',
  height: 'calc(800px - 50vw)',
  ...media(style.mediaMinQueries[Breakpoint.Small], {
    height: '560px',
  }),
});

const ImageHeaderContentWrapper = styled.div({
  color: style.WHITE,
  textShadow: '0 0 10px rgba(0,0,0,0.5)',
  margin: {
    bottom: '80px',
    x: 'auto',
  },
  maxWidth: style.maxContentWidth - 480,
  padding: {
    top: '18px',
    bottom: '0',
  },
  ...media(style.mediaMinQueries[Breakpoint.Small], {
    padding: {
      top: '80px',
    },
  }),
  height: 'calc(690px - 50vw)',
  ...media(style.mediaMinQueries[Breakpoint.Small], {
    height: '450px',
  }),
});

const ImageHeaderFontSize = styled.div({
  fontSize: '1em',
  ...media(style.mediaMinQueries[Breakpoint.Tiny], {
    fontSize: '1.2em',
  }),
});

const H1 = styled.h1({
  margin: {
    top: 0,
    bottom: '32px',
  },
  fontWeight: 500,
});

const News = styled.div({
  textAlign: 'left',
  ...media(style.mediaMinQueries[Breakpoint.Small], {
    display: 'flex',
    flexDirection: 'column',
    margin: {
      bottom: '10px',
      left: 0,
      right: 0,
    },
  }),
});

const NewsInfo = styled.div({
  display: 'flex',
  alignItems: 'center',
  margin: {
    bottom: '15px',
  },
  ...media(style.mediaMinQueries[Breakpoint.Small], {
    margin: {
      top: 0,
      left: 0,
      bottom: '15px',
    },
    alignItems: 'center',
    flexDirection: 'row',
  }),
});

const DateBox = styled.div({
  height: '44px',
  width: '81px',
  backgroundColor: style.primaryDark,
  color: style.WHITE,
  font: {
    size: '16px',
    weight: 500,
  },
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  ...media(style.mediaMinQueries[Breakpoint.Small], {
    height: '60px',
    width: '114px',
    font: {
      size: '24px',
    },
  }),
});

const NewsList = styled.div({
  width: '100%',
  height: 'auto',
  padding: {
    top: '2px',
  },
  ...media(style.mediaMinQueries[Breakpoint.Small], {
    flexDirection: 'column',
    padding: {
      xy: 0,
    },
    margin: {
      top: '13px',
    },
  }),
});

const NewsTitle = styled.h5({
  color: style.primary,
  font: {
    size: '16px',
    weight: 500,
  },
  margin: {
    left: '14px',
    bottom: 0,
  },
  ...media(style.mediaMinQueries[Breakpoint.Small], {
    margin: {
      left: 20,
      bottom: 0,
    },
    font: {
      size: '24px',
    },
  }),
});

const NewsLink = styled(Link, {
  ':hover': {
    textDecoration: 'none',
  },
});

const Preamble = styled.p({
  color: style.BLACK,
});

class NewsListEditorialContentPage extends React.Component<PropType> {
  dateFormat = (item: NewsPageListItem) => {
    const dateMonth = new Date(item.date);
    const locale = 'en-us';
    const month = dateMonth.toLocaleString(locale, {
      month: 'short',
    });
    const dateDay = new Date(item.date).getDate();
    const date = month + ' ' + dateDay;
    return date;
  };

  render() {
    return (
      <>
        <BkgPattern />
        <ImageContainer>
          <BkgImage src={epiPropertyValue(this.props.content.imageUrl)} />
        </ImageContainer>
        <MainWrapper appearance={[Appearance.BackgroundImage, Appearance.Center]}>
          {this.props.breadcrumbs && <AltBreadcrumbs breadcrumbs={this.props.breadcrumbs} />}
          <CenterContent id="mainContent">
            <ImageHeaderContentWrapper>
              <FadeIntoView>
                <ImageHeaderFontSize>
                  <EpiProperty component={H1} for={this.props.content.pageTitle} />
                  <EpiProperty for={this.props.content.headerContent} />
                </ImageHeaderFontSize>
              </FadeIntoView>
            </ImageHeaderContentWrapper>
            {this.props.editorialItems && (
              <>
                <NewsList>
                  {this.props.editorialItems.map((item, i) => (
                    <NewsLink to={item.url} key={i}>
                      <News>
                        <NewsInfo>
                          <DateBox>{this.dateFormat(item)}</DateBox>
                          <NewsTitle>{item.title}</NewsTitle>
                        </NewsInfo>
                        <Preamble>{item.ingress}</Preamble>
                      </News>
                    </NewsLink>
                  ))}
                </NewsList>
              </>
            )}
          </CenterContent>
        </MainWrapper>
      </>
    );
  }
}

export default connect<ConnectStateType>(state => ({
  currentBreakpoint: state.currentBreakpoint,
}))(NewsListEditorialContentPage);
