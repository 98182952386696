/**
 * @ComponentFor EventPageViewModel
 */
import React from 'react';
import { EpiProperty, epiPropertyValue, Breakpoint } from '@avensia/scope';
import { styled } from '@glitz/react';
import { media } from '@glitz/core';
import Viewport from 'Shared/Viewport';
import EventPageViewModelType from './EventPageViewModel.type';
import { Main, Appearance, Heading } from 'Shared/PageLayout';
import connect from 'Shared/connect';
import * as style from 'Shared/Style';

type ConnectStateType = {
  currentBreakpoint: number;
};

type PropType = ConnectStateType & EventPageViewModelType;

const CenterContent = styled.div({
  width: '100%',
  textAlign: 'left',
});

const MainWrapper = styled(Main, {
  width: '100%',
  margin: {
    top: '39px',
    left: 'auto',
    right: 'auto',
  },
  padding: {
    left: '28px',
    right: '28px',
  },
  ...media(style.mediaMinQueries[Breakpoint.Small], {
    margin: {
      top: '80px',
    },
    padding: {
      left: '4vw',
      right: '4vw',
    },
  }),
  ...media(style.mediaMinQueries[Breakpoint.Large], {
    padding: {
      left: '8vw',
      right: '8vw',
    },
  }),
});

const HeadingWrapper = styled.div({
  ...media(style.mediaMinQueries[Breakpoint.Small], {
    paddingTop: '40px',
    paddingRight: 0,
    paddingBottom: '40px',
    paddingLeft: 0,
  }),
});

const BkgPattern = styled.div({
  ...media(style.mediaMinQueries[Breakpoint.Small], {
    backgroundImage:
      'url(' +
      require('../../Shared/Image/PatternLeft.png') +
      '), url(' +
      require('../../Shared/Image/PatternRight.png') +
      ') ',
    backgroundPosition: 'top left, top right',
    backgroundRepeat: 'repeat-y',
    position: 'absolute',
    top: 0,
    bottom: 0,
    width: '100%',
    zIndex: -1,
  }),
});

const MenuBkg = styled.div({
  position: 'absolute',
  background: style.primaryDark,
  zIndex: -1,
  top: 0,
  width: '100%',
  height: '65px',
  ...media(style.mediaMinQueries[Breakpoint.Medium], {
    height: '80px',
  }),
});

const EventImage = styled.img({
  height: '172px',
  display: 'flex',
  width: '100%',
  maxWidth: '400px',
  justifySelf: 'center',
  margin: {
    bottom: '15px',
    left: 'auto',
    right: 'auto',
  },
  ...media(
    { minWidth: '450px' },
    {
      width: '350px',
      height: '185px',
      margin: {
        left: 0,
        right: 0,
      },
    },
  ),
  ...media(style.mediaMinQueries[Breakpoint.Small], {
    minWidth: '400px',
    margin: {
      bottom: 0,
      left: '49px',
      top: '39px',
    },
    height: '235px',
  }),
});

const EventInfo = styled.div({
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  margin: {
    bottom: '20px',
    left: 0,
  },
});

const DateBox = styled.div({
  height: '44px',
  minWidth: '81px',
  width: '81px',
  backgroundColor: style.primaryDark,
  color: style.WHITE,
  font: {
    size: '16px',
    weight: 500,
  },
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  alignSelf: 'flex-start',
  ...media(style.mediaMinQueries[Breakpoint.Small], {
    height: '60px',
    minWidth: '114px',
    width: '114px',
    font: {
      size: '24px',
    },
    margin: {
      right: '15px',
    },
  }),
});

const EventPlace = styled.span({
  color: 'black',
  font: {
    size: '16px',
    weight: 300,
  },
  ...media(style.mediaMinQueries[Breakpoint.Small], {
    font: {
      size: '24px',
      weight: 300,
    },
    margin: {
      bottom: 0,
      left: '6px',
    },
  }),
});

class EventPage extends React.Component<PropType> {
  constructor(props: PropType) {
    super(props);
  }

  dateFormat = (date: string) => {
    const dateMonth = new Date(date);
    const locale = 'en-us';
    const month = dateMonth.toLocaleString(locale, {
      month: 'short',
    });
    const dateDay = new Date(date).getDate();
    const fdate = month + ' ' + dateDay;
    return fdate;
  };

  render() {
    const imageSrc = epiPropertyValue(this.props.content.image);
    return (
      <>
        <BkgPattern />
        <MenuBkg />
        <MainWrapper appearance={[Appearance.BackgroundImage, Appearance.Center]}>
          <HeadingWrapper>
            <Heading title={this.props.content.title} breadcrumbs={this.props.breadcrumbs} />
          </HeadingWrapper>
          <CenterContent id="mainContent">
            <Viewport>
              {(isCompact: boolean) =>
                isCompact && imageSrc && <EventImage src={epiPropertyValue(this.props.content.image)} />
              }
            </Viewport>

            <EventInfo>
              <DateBox>
                {this.props.content.date && epiPropertyValue(this.props.content.date) !== '0001-01-01T00:00:00'
                  ? this.dateFormat(epiPropertyValue(this.props.content.date))
                  : this.props.content.noDateYet}
              </DateBox>
              {this.props.content.place && <EventPlace>{epiPropertyValue(this.props.content.place)}</EventPlace>}
            </EventInfo>
            <styled.P css={{ fontSize: '1.4em' }}>{epiPropertyValue(this.props.content.ingress)}</styled.P>
            <EpiProperty for={this.props.content.mainContent} />

            <Viewport>
              {(isCompact: boolean) =>
                !isCompact && imageSrc && <EventImage src={epiPropertyValue(this.props.content.image)} />
              }
            </Viewport>
          </CenterContent>
        </MainWrapper>
      </>
    );
  }
}

export default connect<ConnectStateType>(state => ({
  currentBreakpoint: state.currentBreakpoint,
}))(EventPage);
