import React from 'react';
import { styled } from '@glitz/react';
import { Ul } from 'Shared/Generic';
import Link from 'Shared/Link';
import BreadcrumbsViewModel from './BreadcrumbsViewModel.type';
import * as style from 'Shared/Style';
import Viewport from 'Shared/Viewport';

type PropType = {
  breadcrumbs: BreadcrumbsViewModel[];
};

export default class AltBreadcrumbs extends React.Component<PropType> {
  render() {
    return (
      <Viewport>
        {(isCompact: boolean) =>
          !isCompact && (
            <List row>
              {this.props.breadcrumbs.map(({ url, text }, index) => (
                <React.Fragment key={url}>
                  {index != 0 && <Arrow>&gt;</Arrow>}
                  <Item key={url}>
                    <Anchor to={url} key={url}>
                      {text}
                    </Anchor>
                  </Item>
                </React.Fragment>
              ))}
            </List>
          )
        }
      </Viewport>
    );
  }
}

const List = styled(Ul, {
  margin: {
    bottom: style.small,
    top: '23px',
  },
  fontSize: '0.9em',
  display: 'flex',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
});

const Item = styled.li({
  display: 'flex',
  alignItems: 'center',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
});

const Arrow = styled.span({
  marginRight: '5px',
  color: 'rgba(255,255,255,0.6)',
  alignSelf: 'center',
});

const Anchor = styled(Link, {
  color: 'rgba(255,255,255,0.6)',
  textTransform: 'uppercase',
  margin: {
    right: '5px',
  },
  ':hover': {
    color: style.WHITE,
    textDecoration: 'none',
  },
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
});
